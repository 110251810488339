import React, { useMemo } from 'react';

import TrashIcon from '@heroicons/react/outline/TrashIcon';
import { CheckIcon } from '/design-systems/Atoms/Icon/check';
import { Button } from '/design-systems/Atoms/Button';
import { Typography } from '/design-systems/Atoms/Typography';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { UserInfo } from './UserInfo';
import { AssetPrice } from '../../Assets/AssetPrice';

export const PromptSubmissionCardHoverDetails = ({
  /** flags */
  canDelete = false,
  producerView = false,
  showShortlistAction = false,
  isShortlisted = false,
  isStoryVariant = false,
  /** states */
  className = '',
  title,
  /** Actions */
  onDelete,
  onShortlist,
  /** Additional render */
  creator,
  showInRow,
  price,
  curatorFeePercentage,
  chain,
  galleryId,
  assetId,
  assetFiles,
  galleryCollabRequests,
  showFeePercentage,
  feePercentageClickable
}) => {
  const tooltipCTA = useMemo(
    () =>
      isShortlisted
        ? 'Remove from shortlist'
        : 'Add to shortlist (only producers can see)',
    [isShortlisted]
  );

  const canDisplayShortlist = useMemo(
    () => producerView && showShortlistAction,
    [producerView, showShortlistAction]
  );

  const hasHoverDetails = useMemo(
    () => canDelete || (producerView && showShortlistAction),
    [canDelete, producerView, showShortlistAction]
  );

  const buttonClassName =
    'flex h-9 w-9 items-center justify-center rounded-full bg-white/20 opacity-0 hover:bg-white/30 group-hover:opacity-100 dark:hover:bg-neutral-600';

  if (isStoryVariant) {
    return (
      <div className={className}>
        <div className="flex flex-col gap-y-4 p-3">
          <Typography
            variant="large"
            weight="semibold"
            className="line-clamp-2"
          >
            {title}
          </Typography>
          <UserInfo
            showInRow={showInRow}
            staticImage={creator.image.static}
            img={creator.image.src}
            transparent={creator.transparent}
            id={creator.id}
            name={creator.name}
          />
        </div>
        <div className="flex flex-col gap-y-4 p-3">
          <AssetPrice
            price={price}
            size={showInRow ? 'large' : 'medium'}
            blockchain={chain}
            feePercentage={curatorFeePercentage}
            showFeePercentage={showFeePercentage}
            feePercentageClickable={feePercentageClickable}
            galleryId={galleryId}
            showChainIcon={false}
            priceClassName="text-xs bg-black opacity-60 text-center mr-1 px-1 py-1 rounded-md"
            feeBtnClassName="button-variant-primary button-color-black opacity-60 text-xs"
            asset={{
              id: assetId,
              title,
              files: assetFiles,
              galleryCollabRequests,
              creator
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {hasHoverDetails && (
        <div className={className}>
          <div className="flex flex-1 flex-col gap-3 p-4 text-white">
            {canDelete && (
              <div className="relative">
                <Button
                  variant="custom"
                  color="custom"
                  iconOnly
                  rounded
                  onClick={onDelete}
                  className={`delete-submission-button ${buttonClassName}`}
                >
                  <TrashIcon className="h-5 w-5 stroke-neutral-50" />
                </Button>
              </div>
            )}

            {canDisplayShortlist && (
              <FloatingTooltip label={tooltipCTA} placement="bottom">
                <div className="flex">
                  <Button
                    variant="icon-only"
                    color="neutral"
                    iconOnly
                    rounded
                    className={`shortlist-submission-button ${buttonClassName}`}
                    onClick={onShortlist}
                  >
                    <CheckIcon className="h-[16px] w-[16px]" color="white" />
                  </Button>
                </div>
              </FloatingTooltip>
            )}
          </div>
        </div>
      )}
    </>
  );
};
