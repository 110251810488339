import React from 'react';

export const CheckIcon = ({ className = '', color = '#2044FF' } = {}) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H12C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2V12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H2C1.46957 14 0.960859 13.7893 0.585786 13.4142C0.210714 13.0391 0 12.5304 0 12V2ZM2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V12C1 12.2652 1.10536 12.5196 1.29289 12.7071C1.48043 12.8946 1.73478 13 2 13H12C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V2C13 1.73478 12.8946 1.48043 12.7071 1.29289C12.5196 1.10536 12.2652 1 12 1H2ZM4 16C3.64894 16 3.30406 15.9076 3.00003 15.732C2.696 15.5565 2.44353 15.304 2.268 15H12C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V2.268C15.304 2.44353 15.5565 2.696 15.732 3.00003C15.9076 3.30406 16 3.64894 16 4V12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16H4ZM10.376 5.33C10.4193 5.28062 10.4525 5.22319 10.4737 5.16099C10.4948 5.09879 10.5035 5.03303 10.4992 4.96747C10.4949 4.90192 10.4778 4.83784 10.4488 4.77891C10.4197 4.71997 10.3794 4.66734 10.33 4.624C10.2806 4.58066 10.2232 4.54748 10.161 4.52634C10.0988 4.50519 10.033 4.49651 9.96747 4.50078C9.90191 4.50505 9.83784 4.5222 9.77891 4.55123C9.71997 4.58027 9.66734 4.62062 9.624 4.67L6.476 8.268L4.854 6.646C4.80751 6.59951 4.75232 6.56264 4.69158 6.53748C4.63084 6.51232 4.56574 6.49937 4.5 6.49937C4.43426 6.49937 4.36916 6.51232 4.30842 6.53748C4.24768 6.56264 4.19249 6.59951 4.146 6.646C4.09951 6.69249 4.06264 6.74768 4.03748 6.80842C4.01232 6.86916 3.99937 6.93426 3.99937 7C3.99937 7.06574 4.01232 7.13084 4.03748 7.19158C4.06264 7.25232 4.09951 7.30751 4.146 7.354L6.146 9.354C6.19446 9.40239 6.25234 9.44032 6.31606 9.46542C6.37978 9.49051 6.44798 9.50226 6.51643 9.49991C6.58487 9.49757 6.6521 9.48119 6.71396 9.45179C6.77581 9.42239 6.83097 9.3806 6.876 9.329L10.376 5.329V5.33Z'
        fill={color}
      />
    </svg>
  );
};
