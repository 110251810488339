import { create } from 'zustand';

export const useAssetSelect = () => {
  const assetIds = useAssetSelectionStore(({ assetIds }) => assetIds);
  const selectAsset = useAssetSelectionStore(({ selectAsset }) => selectAsset);
  const clear = useAssetSelectionStore(({ clear }) => clear);

  return { assetIds, selectAsset, clear };
};

const useAssetSelectionStore = create((set) => ({
  assetIds: [],
  selectAsset: (assetId) =>
    set(({ assetIds }) => {
      if (assetIds.includes(assetId)) return { assetIds: assetIds.filter((id) => id !== assetId) };
      return { assetIds: [...assetIds, assetId] };
    }),
  clear: () => set({ assetIds: [] }),
}));
