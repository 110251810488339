import { removeSpaces } from './url';

const getBackgroundUrl = ({ background, isStaticFile, dimension }) => {
  if (!background) return '';

  const imageUrl = removeSpaces(background);

  let extraParams = '';
  if (dimension) {
    extraParams = `?d=${dimension}`;
  }
  return isStaticFile
    ? imageUrl
    : `${process.env.CLOUDFRONT_URL}/${imageUrl}${extraParams}`;
};

const getBackgroundImageStyle = ({
  background,
  isStaticFile = false,
  dimension
}) => ({
  backgroundImage: `url(${getBackgroundUrl({
    background,
    isStaticFile,
    dimension
  })})`,
  backgroundColor: '#F5F5F5',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
});

export { getBackgroundImageStyle, getBackgroundUrl };
