import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import PlusIcon from '@heroicons/react/outline/PlusIcon';
import XIcon from '@heroicons/react/outline/XIcon';
import EyeOffIcon from '@heroicons/react/outline/EyeOffIcon';
import { Typography } from '/design-systems/Atoms/Typography';
import { Image } from '/design-systems/Atoms/Image';
import { getEditor } from '../../Editor';
import { SaveEntityButton, ENTITY_NAMES } from '../../Buttons/SaveEntityButton';

import { mergeClassNames, cleanTwitterText } from '/utils/string';
import { getImageProps } from '/utils/prompt-submission';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import { emptyArray } from '/utils/object';

export const PromptSubmissionCardImage = ({
  /** flags */
  isStoryVariant = false,
  isShowcaseVariant = false,
  isOtherMedia = false,
  isStandAloneNFT = false,
  isIOS = false,
  isFeaturedAsset = false,
  showFeaturedAssets = false,
  isSelectingAsset = false,
  showInRow = false,
  canApplyStylesForHiddens = false,
  producerViewForStatusChange = false,
  withSave = true,

  /** states */
  className = '',
  description,
  files = [],
  tempMediaUrl,
  tempMediaType,
  imgWidth,
  heightVariant,
  hiddenMessage,
  saved,
  assetId,
  isPreview = false,

  /** actions */
  setImgHeight,
  setImgWidth,
  onChangeStatus
}) => {
  const editor = getEditor(description);

  const { isPromptSubmissionCardImageBgEnabled } = useFeatureFlags();
  const maxDimensionsClassName = useMemo(
    () =>
      heightVariant === 'small'
        ? 'max-h-[calc(100%-2rem)] max-w-[calc(100%-2rem)]'
        : 'max-h-[calc(100%-6rem)] max-w-[calc(100%-6rem)]',
    [heightVariant]
  );

  const renderImage = () => {
    if (isStoryVariant) {
      return (
        <>
          {isOtherMedia ? (
            <Typography className="p-4 leading-7 md:p-6">
              {editor &&
                (description && description.length > 280
                  ? `${editor.getText().substring(0, 280)}...`
                  : editor.getText() || '')}
            </Typography>
          ) : (
            <>
              <Image
                {...getImageProps(files)}
                className="h-full w-full object-contain transition-all duration-150 group-hover:scale-[1.02]"
                onImageLoad={(e) => {
                  setImgHeight(e.nativeEvent.srcElement.naturalHeight);
                  setImgWidth(e.nativeEvent.srcElement.naturalWidth);
                }}
                onVideoLoad={(e) => {
                  setImgHeight(e.nativeEvent.srcElement.videoHeight);
                  setImgWidth(e.nativeEvent.srcElement.videoWidth);
                }}
                resolution={imgWidth > 600 ? 'xl' : 'l'}
              />
            </>
          )}
        </>
      );
    }

    return (
      <>
        {isOtherMedia && !isStandAloneNFT ? (
          showInRow ? (
            <Image
              {...getImageProps(files || [])}
              className={mergeClassNames(
                'h-full w-full',
                canApplyStylesForHiddens && 'opacity-30'
              )}
              style={{
                objectFit: 'cover'
              }}
            />
          ) : (
            <>
              {!tempMediaUrl && (
                <Typography className="p-4 leading-6">
                  {editor &&
                    description &&
                    cleanTwitterText(editor.getText() || '')}
                </Typography>
              )}
            </>
          )
        ) : (
          <Image
            {...getImageProps(files)}
            className={mergeClassNames(
              'absolute',
              maxDimensionsClassName,
              'rounded-md object-contain drop-shadow-md transition-all duration-150',
              !isIOS && 'h-fit w-fit',
              showInRow
                ? 'group-hover/card-img:scale-[1.02]'
                : 'group-hover:scale-[1.02]',
              canApplyStylesForHiddens && 'opacity-30'
            )}
            onImageLoad={(e) => {
              setImgHeight(e.nativeEvent.srcElement.naturalHeight);
              setImgWidth(e.nativeEvent.srcElement.naturalWidth);
            }}
            onVideoLoad={(e) => {
              setImgHeight(e.nativeEvent.srcElement.videoHeight);
              setImgWidth(e.nativeEvent.srcElement.videoWidth);
            }}
            resolution={
              showInRow && !isMobile
                ? 'xl'
                : heightVariant === 'small'
                ? 's'
                : 'm'
            }
          />
        )}

        {tempMediaUrl && emptyArray(files) && (
          <Image
            src={tempMediaUrl}
            fileType={tempMediaType}
            staticFile
            className={mergeClassNames(
              'absolute',
              maxDimensionsClassName,
              'rounded-md object-contain drop-shadow-md transition-all duration-150',
              !isIOS && 'h-fit w-fit',
              'group-hover/card-img:scale-[1.02]'
            )}
            resolution="m"
          />
        )}
      </>
    );
  };

  return (
    <div
      className={mergeClassNames(
        className,
        !isStoryVariant && 'flex items-center justify-center'
      )}
    >
      {/* background blur layer */}
      {isPromptSubmissionCardImageBgEnabled && isShowcaseVariant && (
        <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full rounded-t-[12px] bg-white/20 backdrop-blur-[120px]" />
      )}

      {renderImage()}

      {producerViewForStatusChange && onChangeStatus && showFeaturedAssets && (
        <button
          className="absolute left-4 top-4 z-20 flex h-9 w-9 flex-row items-center gap-1 rounded-full bg-black/20 p-2 text-white hover:bg-black/30"
          onClick={(event) => {
            event.stopPropagation();
            onChangeStatus(event);
          }}
          title={isFeaturedAsset ? 'Remove' : 'Add to Featured'}
        >
          {isFeaturedAsset ? <XIcon /> : <PlusIcon />}
        </button>
      )}
      {withSave && !isPreview && !isSelectingAsset && (
        <div className="absolute right-4 top-4 z-20">
          <SaveEntityButton
            entityId={assetId}
            entity={ENTITY_NAMES.ASSET}
            saved={saved}
            iconClassName="text-white dark:text-neutral-100"
          />
        </div>
      )}
      {canApplyStylesForHiddens && (
        <Typography
          className="hidden-asset-text absolute top-40 w-full text-center text-black"
          weight="medium"
        >
          <div className="flex items-center justify-center gap-x-2">
            <EyeOffIcon className="h-6 w-6" /> {hiddenMessage}
          </div>
        </Typography>
      )}
    </div>
  );
};
