import React from 'react';

export const CheckCircleIcon = ({ className = '', fill = '#2044FF' } = {}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      cx="15"
      cy="15"
      r="14"
      fill={fill}
      stroke="#FAFAFA"
      strokeWidth="2"
    />
    <path
      d="M8.83301 15.9286L12.5473 19.6429L21.833 10.3571"
      stroke="white"
      strokeWidth="2.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
