import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isIOS } from 'react-device-detect';

import EyeIcon from '@heroicons/react/outline/EyeIcon';
import EyeOffIcon from '@heroicons/react/outline/EyeOffIcon';
import { CheckCircleIcon } from '/design-systems/Atoms/Icon/check-circle';
import { CircleIcon } from '/design-systems/Atoms/Icon/circle';
import { MergeIcon } from '/design-systems/Atoms/Icon/merge';
import { IconListItem } from '/design-systems/Atoms/IconListItem';
import { PromptSubmissionCardDetails } from './PromptSubmissionCardDetails';
import { PromptSubmissionCardHoverDetails } from './PromptSubmissionCardHoverDetails';
import { PromptSubmissionCardImage } from './PromptSubmissionCardImage';
import { PromptSubmissionActionButton } from './PromptSubmissionActionButton';

import { useModalManager, MODALS } from '/context/ModalManager';

import useToggle from '/hooks/useToggle';
import { useAssetSelect } from '/hooks/useAssetSelect';
import { useTokenOwner } from '/hooks/useTokenOwner';

import { getImageProps } from '/utils/prompt-submission';
import { generateQueryParams, removeQueryParam } from '/utils/url';
import { getFirstMediaFileType } from '/utils/file';

import { mergeClassNames } from '/utils/string';
import { emptyArray, objectBlank } from '/utils/object';
import { getBackgroundUrl } from '/utils/style';
import {
  META_ENTITIES,
  PROMPT_SUBMISSION_CARD_ACTION_NAMES,
  orderType
} from '/utils/constants';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTES } from '/config/routes';

/**
 * @param type image | video | document | music | other
 */
export const PromptSubmissionCard = ({
  allowMinting,
  title,
  description,
  files,
  tempMediaUrl,
  tempMediaType,
  isShortlisted,
  producerView,
  canDelete,
  contributorId,
  contributorImg,
  contributorName,
  creatorId,
  creatorImg,
  creatorName,
  staticImage = false,
  showInRow = false,
  isFeaturedHome = false,
  onDelete,
  onShortlist,
  isLong = false,
  noBorder = false,
  transparent = false,
  heightVariant,
  isAssetMinted,
  price,
  lastSale,
  curatorFeePercentage,
  className,
  cardImageClassName: _cardImageClassName,
  submission,
  withShortlistedBy = false,
  withSave = true,
  saved = false,
  assetId,
  chain,
  onChangeStatus,
  isFeaturedAsset,
  showFeaturedAssets,
  producerViewForStatusChange = false,
  isSubmission = false,
  isAsset = false,
  isStandAloneNFT = false,
  contractAddress,
  contractTokenId,
  isPreview = false,
  queryParam,
  onPageChange,
  ended,
  promptId,
  contestId,
  projectId,
  onDeleteFromPreviewModal,
  onShortlistFromPreviewModal,
  tokenId, // this is not the token ID in the contract
  hidden = false,
  hideButtonSuffix = '',
  withHide = false,
  applyStylesForHiddens = false,
  hiddenMessage = '',
  onHide,
  withMerge = false,
  variant = 'showcase',
  galleryId,
  canManageGallery = false,
  showShortlistAction = true,
  offerWindowDetails,
  tokenOwners,
  tokenIdForListing,
  tokenOrders,
  isTokenOwner: isTokenOwner_ = false,
  hideDescription = false,
  openFullPage = false,
  anchorId,
  totalSupply = 0,
  galleryCollabRequests = []
}) => {
  const creator = useMemo(
    () => ({
      id: creatorId,
      name: creatorName,
      image: {
        src: creatorImg,
        static: false
      },
      transparent
    }),
    [creatorId, creatorName, creatorImg, transparent]
  );
  const contributor = useMemo(
    () => ({
      id: contributorId,
      name: contributorName,
      image: {
        src: contributorImg,
        static: false
      },
      transparent
    }),
    [contributorId, contributorName, contributorImg, transparent]
  );
  const { showModal, closeModal } = useModalManager();
  const router = useRouter();
  const { isPromptSubmissionCardImageBgEnabled } = useFeatureFlags();
  const { freeSeaportListing } = useFlags();

  const [isHidden, toggleHidden] = useToggle(hidden);
  const { assetIds, selectAsset } = useAssetSelect();

  const showFeePercentage = useMemo(() => {
    if (galleryId) {
      return canManageGallery;
    }
    return curatorFeePercentage > 0;
  }, [galleryId, canManageGallery, curatorFeePercentage]);
  // should only be clickable by gallery admin, in the context of a gallery
  const feePercentageClickable = useMemo(
    () => galleryId && canManageGallery,
    [galleryId, canManageGallery]
  );

  const { tokenOwner } = useTokenOwner({
    tokenId: tokenIdForListing
  });

  const isTokenOwner = useMemo(
    () => tokenOwner?.isOwner || isTokenOwner_,
    [tokenOwner, isTokenOwner_]
  );

  const isFreeListing = useMemo(() => {
    return freeSeaportListing ? Number(price) === 0 && !!price : false;
  }, [price, freeSeaportListing]);

  const hasOfferWindow = useMemo(() => {
    if (objectBlank(offerWindowDetails)) return false;

    if (offerWindowDetails.startDate && offerWindowDetails.endDate) {
      return (
        new Date(offerWindowDetails.startDate) <= new Date() &&
        new Date(offerWindowDetails.endDate) > new Date()
      );
    }

    return true;
  }, [offerWindowDetails]);

  const hasActiveListing = useMemo(() => {
    return (
      tokenOrders?.filter(
        (order) => order.isActive === true && order.type === orderType.LISTING
      )?.length > 0
    );
  }, [tokenOrders]);

  useEffect(
    () => {
      if (hidden !== isHidden) toggleHidden(hidden);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hidden]
  );

  const submissionCardId = useMemo(
    () => (isSubmission ? submission?.id : isAsset ? assetId : tokenId),
    [assetId, isAsset, isSubmission, submission?.id, tokenId]
  );

  const isAssetSelected = useMemo(
    () => assetIds.includes(submissionCardId),
    [assetIds, submissionCardId]
  );

  const isSelectingAsset = useMemo(
    () => !emptyArray(assetIds) && withMerge,
    [assetIds, withMerge]
  );

  const isStoryVariant = useMemo(
    () => variant === META_ENTITIES.STORY || variant === META_ENTITIES.GALLERY,
    [variant]
  );

  const isShowcaseVariant = useMemo(
    () => variant === META_ENTITIES.SHOWCASE,
    [variant]
  );

  const imgHeightClassName = useMemo(() => {
    if (isStoryVariant) return '';

    if (isLong) {
      return 'h-[410px]';
    }

    if (heightVariant === 'small') {
      return `h-[200px]`;
    }

    return 'aspect-square';
  }, [isStoryVariant, isLong, heightVariant]);

  const imgWidthClassName = useMemo(() => {
    if (showInRow) return 'w-full md:w-3/5';
    return 'w-full';
  }, [showInRow]);

  const [imgHeight, setImgHeight] = useState(null);
  const [imgWidth, setImgWidth] = useState(0);

  const isOtherMedia = useMemo(
    () => getFirstMediaFileType(files) === 'other',
    [files]
  );

  const cardClassName = useMemo(() => {
    const baseClasses = mergeClassNames(
      'group relative prompt-submission-card',
      'cursor-pointer',
      'rounded-xl',
      !(noBorder || showInRow) && 'border border-neutral-200 dark:border-none',
      className
    );

    if (isStoryVariant) {
      return mergeClassNames(
        baseClasses,
        imgWidth && imgHeight ? `flex-[${imgWidth / imgHeight}]` : 'flex-1',
        'hover:scale-102 duration-150',
        'shadow-lg',
        isOtherMedia && 'min-w-[200px]',
        transparent ? 'bg-white bg-opacity-[15%]' : 'bg-white',
        transparent && 'bg-white/20'
      );
    }

    if (showInRow) {
      return mergeClassNames(
        baseClasses,
        'flex flex-col md:flex-row items-center gap-4',
        'bg-none'
      );
    }

    return mergeClassNames(
      baseClasses,
      `shadow-none`,
      isPreview && 'border border-neutral-300 dark:border-neutral-800',
      !isPromptSubmissionCardImageBgEnabled &&
        'hover:scale-102 hover:-translate-y-1 duration-150',
      isAssetSelected && 'outline outline-2 outline-black',
      transparent ? 'bg-white bg-opacity-[15%]' : 'bg-white'
    );
  }, [
    noBorder,
    showInRow,
    className,
    isStoryVariant,
    isPreview,
    isPromptSubmissionCardImageBgEnabled,
    isAssetSelected,
    transparent,
    imgWidth,
    imgHeight,
    isOtherMedia
  ]);

  const cardBorderRadius = useMemo(() => {
    if (isStoryVariant || showInRow) return 'rounded-xl';

    return 'rounded-t-xl';
  }, [isStoryVariant, showInRow]);

  const cardImageClassName = useMemo(() => {
    const baseClasses = mergeClassNames(
      imgHeightClassName,
      imgWidthClassName,
      'group/card-img',
      'relative overflow-hidden',
      cardBorderRadius
    );

    if (isStoryVariant) {
      return mergeClassNames(baseClasses, 'h-full');
    }

    return mergeClassNames(
      baseClasses,
      'flex bg-neutral-100 dark:bg-neutral-900',
      _cardImageClassName,
      showInRow ? 'p-12' : 'p-6',
      isPromptSubmissionCardImageBgEnabled &&
        `bg-[url('${getBackgroundUrl({
          background: getImageProps(files)?.src,
          dimension: 'xs'
        })}')] bg-cover bg-center bg-no-repeat`
    );
  }, [
    imgHeightClassName,
    imgWidthClassName,
    cardBorderRadius,
    _cardImageClassName,
    isStoryVariant,
    showInRow,
    isPromptSubmissionCardImageBgEnabled,
    files
  ]);

  const hoverDetailsClassName = useMemo(() => {
    if (isStoryVariant) {
      return mergeClassNames(
        'absolute left-0 top-0 flex flex-col',
        'w-full',
        cardBorderRadius,
        'bg-black/20 text-white opacity-0 group-hover:opacity-100',
        isStoryVariant ? 'h-full' : 'h-[calc(100%-48px)]'
      );
    }

    return mergeClassNames(
      'absolute left-0 top-0 z-10',
      cardBorderRadius,
      imgHeightClassName,
      'flex w-full flex-col',
      'bg-neutral-900/60 text-white opacity-0 group-hover:opacity-100',
      showInRow && 'hidden'
    );
  }, [isStoryVariant, cardBorderRadius, imgHeightClassName, showInRow]);

  const queryParamName = isAsset
    ? 'assetId'
    : isSubmission
    ? 'submissionId'
    : 'tokenId';

  const options = useMemo(() => {
    const props = { flat: true, className: '!p-0', rowClassName: '!p-0' };
    return [
      ...(withMerge
        ? [
            <IconListItem
              key={1}
              icon={<MergeIcon className="h-6 w-6" />}
              title="Merge duplicates"
              name={PROMPT_SUBMISSION_CARD_ACTION_NAMES.MERGE}
              {...props}
            />
          ]
        : []),
      ...(withHide
        ? [
            <IconListItem
              key={0}
              icon={
                isHidden ? (
                  <EyeIcon className="h-6 w-6" />
                ) : (
                  <EyeOffIcon className="h-6 w-6" />
                )
              }
              title={`${isHidden ? 'Show' : 'Hide'} ${hideButtonSuffix}`}
              name={PROMPT_SUBMISSION_CARD_ACTION_NAMES.HIDE}
              id="show-hide-asset-btn"
              {...props}
            />
          ]
        : [])
    ];
  }, [isHidden, withMerge, withHide, hideButtonSuffix]);

  const shortlistedByUsers = useMemo(
    () =>
      submission?.ShortlistedBy?.map((User) => ({
        id: User.id,
        avatar: User?.Files?.[0]?.fileUrl,
        username: User?.username,
        name: User?.name,
        WalletAddresses: User?.WalletAddresses
      })) || [],
    [submission]
  );

  // Apply opacity to only Asset card
  const canApplyStylesForHiddens = useMemo(
    () => isHidden && applyStylesForHiddens,
    [isHidden, applyStylesForHiddens]
  );

  const commonProps = {
    isPreview,
    withPagination: !!onPageChange,
    onPageChange,
    ended,
    promptId,
    contestId,
    projectId,
    onShortlist: onShortlistFromPreviewModal,
    withSave,
    allowMinting,
    galleryId,
    onDelete: (e) => onDeleteFromPreviewModal?.(e, queryParam),
    onClose: () =>
      router.push(
        removeQueryParam(router.asPath, ['assetId', 'tokenId', 'submissionId']),
        undefined,
        {
          shallow: true
        }
      )
  };

  useEffect(
    () => {
      if (!router.isReady) return;

      const {
        assetId: query_assetId,
        tokenId: query_tokenId,
        submissionId: query_submissionId
      } = router.query ?? {};

      const isAssetSelected = Boolean(query_assetId);
      const isTokenSelected = Boolean(query_tokenId);
      const isSubmissionSelected = Boolean(query_submissionId);

      // If no asset or token or submission is selected
      if (!isAssetSelected && !isTokenSelected && !isSubmissionSelected) {
        return;
      }

      const additionalProps = {};

      switch (true) {
        // if asset is selected
        case isAssetSelected:
          additionalProps.isAsset = true;
          additionalProps.queryParam = +query_assetId;
          break;

        // if standalone nft is selected
        case isTokenSelected:
          additionalProps.isStandAloneNFT = true;
          additionalProps.queryParam = query_tokenId;
          break;

        // if submission is selected
        case isSubmissionSelected:
          additionalProps.isSubmission = true;
          additionalProps.queryParam = +query_submissionId;
          break;

        default:
          return;
      }

      showModal({
        modalId: MODALS.PREVIEW_MODAL,
        props: {
          ...commonProps,
          ...additionalProps
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const handleMerge = useCallback(() => {
    selectAsset(submissionCardId);
  }, [selectAsset, submissionCardId]);

  const handleView = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (isSelectingAsset) {
        handleMerge();
        return;
      }

      if (isPreview) {
        return;
      }

      if (isSubmission && (e.ctrlKey || e.metaKey)) {
        window.open(
          `/contest/${router?.query?.contestId}?submissionId=${submission.id}`,
          '_blank'
        );
        return;
      }

      let pathname;
      const queryParams = {};

      if (openFullPage) {
        pathname = ROUTES.asset.view(assetId);
        queryParams.galleryType = variant;
        if (anchorId) {
          queryParams.anchorId = anchorId;
        }
        if (galleryId) {
          queryParams.galleryId = galleryId;
        }
        if (router.query?.galleryId) {
          queryParams.gallerySlug = router.query?.galleryId;
        }
      } else {
        // open modal
        pathname = router.pathname;

        if (isSubmission) {
          queryParams[queryParamName] = submission.id;
        } else if (isAsset) {
          queryParams[queryParamName] = assetId;
        } else if (isStandAloneNFT) {
          queryParams[queryParamName] = tokenId;
        }
      }

      if (e.ctrlKey || e.metaKey) {
        // open in new tab
        window.open(ROUTES.asset.view(assetId), '_blank');
      } else {
        router.push(
          {
            pathname: pathname,
            query: { ...router.query, ...queryParams }
          },
          undefined,
          { scroll: openFullPage }
        );
      }
    },
    [
      isSelectingAsset,
      isPreview,
      isSubmission,
      openFullPage,
      handleMerge,
      router,
      submission,
      assetId,
      variant,
      anchorId,
      isAsset,
      isStandAloneNFT,
      queryParamName,
      tokenId
    ]
  );

  const handleHide = useCallback(
    () => {
      onHide(!isHidden);
      toggleHidden();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHidden, onHide]
  );

  return (
    <div
      className={mergeClassNames(cardClassName, className)}
      onClick={handleView}
    >
      {isSelectingAsset && (
        <div className="absolute right-4 top-4 z-50">
          {isAssetSelected ? (
            <CheckCircleIcon className="h-8 w-8" fill="#111827" />
          ) : (
            <CircleIcon className="h-8 w-8" fill="rgba(17, 24, 39, 0.5)" />
          )}
        </div>
      )}

      {/* card image */}
      <PromptSubmissionCardImage
        isStoryVariant={isStoryVariant}
        isShowcaseVariant={isShowcaseVariant}
        isOtherMedia={isOtherMedia}
        isStandAloneNFT={isStandAloneNFT}
        isIOS={isIOS}
        isFeaturedAsset={isFeaturedAsset}
        showFeaturedAssets={showFeaturedAssets}
        isSelectingAsset={isSelectingAsset}
        producerView={producerView}
        showInRow={showInRow}
        canApplyStylesForHiddens={canApplyStylesForHiddens}
        producerViewForStatusChange={producerViewForStatusChange}
        className={cardImageClassName}
        submissionCardId={submissionCardId}
        description={description}
        tempMediaUrl={tempMediaUrl}
        tempMediaType={tempMediaType}
        files={files}
        imgWidth={imgWidth}
        heightVariant={heightVariant}
        hiddenMessage={hiddenMessage}
        setImgHeight={setImgHeight}
        setImgWidth={setImgWidth}
        onChangeStatus={onChangeStatus}
        isPreview={isPreview}
        withSave={withSave}
        saved={saved}
        assetId={assetId}
      />

      {!showInRow && (
        <PromptSubmissionCardHoverDetails
          canDelete={canDelete}
          producerView={producerView}
          showShortlistAction={showShortlistAction}
          isShortlisted={isShortlisted}
          isStoryVariant={isStoryVariant}
          className={hoverDetailsClassName}
          title={title}
          onDelete={onDelete}
          onShortlist={onShortlist}
          showInRow={showInRow}
          creator={contributor}
          price={price}
          curatorFeePercentage={curatorFeePercentage}
          chain={chain}
          galleryId={galleryId}
          assetId={assetId}
          assetFiles={files}
          galleryCollabRequests={galleryCollabRequests}
          showFeePercentage={showFeePercentage}
          feePercentageClickable={feePercentageClickable}
        />
      )}

      {!isStoryVariant && (
        <PromptSubmissionCardDetails
          showInRow={showInRow}
          isFeaturedHome={isFeaturedHome}
          canApplyStylesForHiddens={canApplyStylesForHiddens}
          hasOfferWindow={hasOfferWindow}
          hasActiveListing={hasActiveListing}
          isAssetMinted={isAssetMinted}
          isTokenOwner={isTokenOwner}
          totalSupply={totalSupply}
          withShortlistedBy={withShortlistedBy}
          hideDescription={hideDescription}
          title={title}
          description={description}
          offerWindowDetails={offerWindowDetails}
          tokenOwners={tokenOwners}
          shortlistedByUsers={shortlistedByUsers}
          price={price}
          lastSale={lastSale}
          producerView={producerView}
          chain={chain}
          actionOptions={options}
          onMerge={handleMerge}
          onHide={handleHide}
          onViewSubmission={handleView}
          isFreeListing={isFreeListing}
          curatorFeePercentage={curatorFeePercentage}
          galleryCollabRequests={galleryCollabRequests}
          assetFiles={files}
          galleryId={galleryId}
          assetId={assetId}
          creator={creator}
          contributor={contributor}
          showFeePercentage={showFeePercentage}
          feePercentageClickable={feePercentageClickable}
        />
      )}

      {!showInRow && !producerView && (
        <PromptSubmissionActionButton
          assetId={assetId}
          price={price}
          network={chain}
          isOwner={isTokenOwner}
          applyRightMargin={!emptyArray(options)}
          isFreeListing={isFreeListing}
          chain={chain}
          contractAddress={contractAddress}
          tokenId={contractTokenId}
        />
      )}
    </div>
  );
};
