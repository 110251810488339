import React, { useCallback, useMemo } from 'react';
import DotsVerticalIcon from '@heroicons/react/outline/DotsVerticalIcon';

import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { NetworkIcon } from '/design-systems/Atoms/Icon/network';
import { FloatingDropdown } from '/design-systems/Atoms/FloatingDropdown';
import { Team } from '../../Team';
import { AssetPrice } from '../../Assets/AssetPrice';
import { OfferWindowDetails } from '../../OfferWindow';
import { ProductOwner } from '../../Product/ProductOwner';
import { ProductEditionInfo } from '/design-systems/Molecules/Product/ProductContent/ProductEditionInfo';
import { getEditor } from '../../Editor';
import { UserInfo } from './UserInfo';

import { mergeClassNames, isEmptyDOMString, toShort18 } from '/utils/string';
import { emptyArray, objectBlank } from '/utils/object';
import { PROMPT_SUBMISSION_CARD_ACTION_NAMES } from '/utils/constants';
import { useRouter } from 'next/router';
import { ROUTES } from '/config/routes';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import FreeClaimDetails from '../MarketplaceCard/FreeClaimDetails';
import { toBigInt } from 'ethers';

export const PromptSubmissionCardDetails = ({
  /** flags */
  showInRow = false,
  isFeaturedHome = false,
  canApplyStylesForHiddens = false,
  hasOfferWindow = false,
  hasActiveListing = false,
  isAssetMinted = false,
  isTokenOwner = false,
  isFreeListing,
  withShortlistedBy = false,
  hideDescription = false,

  /** states */
  title,
  description,
  actionOptions = [],
  offerWindowDetails,
  tokenOwners = [],
  shortlistedByUsers = [],
  price,
  lastSale,
  chain,
  totalSupply,
  curatorFeePercentage,

  /** actions */
  onMerge,
  onHide,
  onViewSubmission,

  /** additional renderer */

  // gallery collab request modal
  galleryCollabRequests,
  assetFiles,
  galleryId,
  assetId,
  creator,
  contributor,
  showFeePercentage,
  feePercentageClickable
}) => {
  const isDiffCreator = useMemo(() => {
    if (!creator.id || !contributor.id) {
      return false;
    }

    return Number(creator.id) !== Number(contributor.id);
  }, [creator?.id, contributor?.id]);
  const router = useRouter();
  const editor = getEditor(description);
  const { isMarketplaceFrontendEnabled } = useFeatureFlags();

  const canDisplayBlockchainNetwork = useMemo(
    () =>
      isMarketplaceFrontendEnabled &&
      isAssetMinted &&
      !lastSale &&
      !price &&
      !showInRow,
    [isAssetMinted, isMarketplaceFrontendEnabled, lastSale, price, showInRow]
  );

  const titleProps = useMemo(() => {
    if (isFeaturedHome) {
      return {
        heading: true,
        variant: 'h1',
        weight: 'semibold'
      };
    }

    if (showInRow) {
      return {
        heading: true,
        variant: 'h3',
        weight: 'semibold'
      };
    }

    return {
      heading: false,
      variant: 'medium',
      weight: 'semibold'
    };
  }, [showInRow, isFeaturedHome]);

  const isAnEdition = useMemo(() => {
    return totalSupply && toBigInt(totalSupply) > toBigInt(1);
  }, [totalSupply]);

  const handleClickDropdownMenu = useCallback(
    (element, event) => {
      event.preventDefault();
      event.stopPropagation();

      switch (element.props.name) {
        case PROMPT_SUBMISSION_CARD_ACTION_NAMES.MERGE:
          onMerge();
          break;
        case PROMPT_SUBMISSION_CARD_ACTION_NAMES.HIDE:
          onHide();
          break;
        default:
          break;
      }
    },
    [onMerge, onHide]
  );

  const handleProfileClick = useCallback(
    (userInfo) => {
      router.push(ROUTES.profile.view(userInfo));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div
      className={mergeClassNames(
        'prompt-submission-card--details group relative',
        showInRow ? 'p-0 md:p-3' : 'p-3',
        showInRow && 'w-full flex-1'
      )}
    >
      <div
        className={mergeClassNames(
          !showInRow && 'flex items-center justify-between gap-2'
        )}
      >
        <div
          className={mergeClassNames(
            'flex w-full max-w-full flex-col',
            showInRow ? 'gap-3 md:gap-6' : 'gap-2'
          )}
        >
          <div
            className={mergeClassNames(
              'flex flex-col',
              isFeaturedHome ? 'gap-6' : 'gap-4'
            )}
          >
            <div className="flex items-center justify-between gap-4">
              {title && <Typography {...titleProps}>{title}</Typography>}
              {Boolean(isAnEdition) && !showInRow && (
                <Typography colorVariant="secondary">x{totalSupply}</Typography>
              )}
            </div>

            {Boolean(isAnEdition) && showInRow && (
              <ProductEditionInfo amount={totalSupply} />
            )}

            <div className="flex items-center justify-between gap-4">
              <div
                className={mergeClassNames(
                  'w-full overflow-hidden',
                  canApplyStylesForHiddens && 'opacity-30'
                )}
              >
                <div className="flex max-w-full items-center justify-between gap-3 overflow-hidden">
                  {isDiffCreator && (
                    <UserInfo
                      showInRow={showInRow}
                      staticImage={creator.image.static}
                      img={creator.image.src}
                      transparent={creator.transparent}
                      id={creator.id}
                      name={creator.name}
                      title={isDiffCreator ? 'Created by' : ''}
                    />
                  )}
                  <UserInfo
                    showInRow={showInRow}
                    staticImage={contributor.image.static}
                    img={contributor.image.src}
                    transparent={contributor.transparent}
                    id={contributor.id}
                    name={contributor.name}
                    title={isDiffCreator ? 'Submitted by' : ''}
                  />
                </div>
              </div>
              <div className="flex min-w-[20px] items-center justify-end gap-4">
                {canDisplayBlockchainNetwork && (
                  <NetworkIcon
                    chain={chain}
                    className={mergeClassNames(
                      'h-5 w-5 object-cover',
                      !emptyArray(actionOptions) && 'mr-10'
                    )}
                  />
                )}

                {!emptyArray(actionOptions) && (
                  <div className="absolute right-1 z-30 flex">
                    <FloatingDropdown
                      items={actionOptions}
                      onDropDownClick={handleClickDropdownMenu}
                      placement="top-end"
                    >
                      <Button
                        className="prompt-submission-card-actions min-h-[40px] min-w-[40px]"
                        type="button"
                        variant="custom"
                        color="custom"
                        iconOnly
                        rounded
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DotsVerticalIcon className="h-[20px] w-[20px]" />
                      </Button>
                    </FloatingDropdown>
                  </div>
                )}
              </div>
            </div>

            {showInRow &&
              !isEmptyDOMString(description) &&
              !hideDescription && (
                <Typography
                  className="mt-4"
                  variant="medium"
                  colorVariant="secondary"
                  overflow="!line-clamp-3"
                  style={{ 'word-break': 'break-word' }}
                >
                  {editor && (editor.getText() || '')}
                </Typography>
              )}
          </div>

          {hasOfferWindow ? (
            <OfferWindowDetails
              offerLabel={
                offerWindowDetails?.Orders?.[0]?.priceUnit
                  ? 'Highest bid'
                  : 'Reserve'
              }
              offerPrice={
                offerWindowDetails?.Orders?.[0]?.priceUnit
                  ? toShort18(offerWindowDetails?.Orders?.[0]?.priceUnit)
                  : toShort18(offerWindowDetails?.reservePrice)
              }
              offerPriceTextSize={showInRow ? 'large' : 'medium'}
              countdownLabel={'Ends in'}
              countdownStyle="gap-1 min-w-[140px]"
              countdownLabelSize={showInRow ? 'medium' : 'small'}
              countdownTypographyVariant={showInRow && 'h5'}
              countdownTime={offerWindowDetails?.endDate}
              className="my-2 items-start justify-between gap-4"
              blockchain={chain}
              auctionId={offerWindowDetails?.id}
              withCountDown={!!offerWindowDetails?.endDate}
            />
          ) : (
            <>
              {isFreeListing ? (
                <FreeClaimDetails
                  classname="mt-2 flex flex-col gap-1"
                  variant={showInRow ? 'large' : 'medium'}
                />
              ) : (
                <div className="mt-2 flex flex-col gap-2">
                  <AssetPrice
                    className={mergeClassNames(
                      showInRow ? 'flex flex-col !items-start gap-4' : 'flex',
                      canApplyStylesForHiddens && 'opacity-30'
                    )}
                    label={price ? 'Buy Now' : 'Last sold'}
                    price={price || lastSale}
                    size={showInRow ? 'large' : 'medium'}
                    blockchain={chain}
                    feePercentage={curatorFeePercentage}
                    showFeePercentage={showFeePercentage}
                    feePercentageClickable={feePercentageClickable}
                    galleryId={galleryId}
                    feeBtnClassName={
                      feePercentageClickable && 'button-variant-secondary'
                    }
                    asset={{
                      id: assetId,
                      title,
                      files: assetFiles,
                      galleryCollabRequests,
                      creator: contributor
                    }}
                  />
                </div>
              )}
              {isAssetMinted && (price || lastSale) && (
                <>
                  {!isAnEdition &&
                    tokenOwners?.length === 1 &&
                    Boolean(lastSale) && (
                      <div className="mt-2 flex justify-between gap-2">
                        <div className="mt-2">
                          <ProductOwner
                            title="Collector"
                            owner={tokenOwners?.[0]?.WalletAddress?.User}
                            tweet={''}
                            address={tokenOwners?.[0]?.ownerAddress}
                            withRedirectProfile={!objectBlank(tokenOwners?.[0])}
                            fontSize={24}
                            avatarSize={24}
                          />
                        </div>
                      </div>
                    )}

                  {showInRow && !isTokenOwner && hasActiveListing && (
                    <Button
                      id="buynow-btn-showcase"
                      color="black"
                      variant="primary"
                      onClick={onViewSubmission}
                      className="w-fit"
                    >
                      Buy now
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {withShortlistedBy && !emptyArray(shortlistedByUsers) && (
        <div
          className={mergeClassNames(
            'mt-4',
            canApplyStylesForHiddens && 'opacity-30'
          )}
        >
          <Typography
            className="mb-1"
            colorVariant="secondary"
            variant="xsmall"
          >
            Shortlisted By
          </Typography>
          <Team
            users={shortlistedByUsers}
            usersCount={shortlistedByUsers.length}
            required={4}
            size={32}
            rounded
            noHover
            noWrap
            noLabel
            handleProfileClick={handleProfileClick}
          />
        </div>
      )}
    </div>
  );
};
