import React from 'react';

export const MergeIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.4 21L5 19.6L9.825 14.75C10.2083 14.3667 10.5 13.9333 10.7 13.45C10.9 12.9667 11 12.4583 11 11.925V6.825L9.4 8.4L8 7L12 3L16 7L14.6 8.4L13 6.825V11.925C13 12.4583 13.1 12.9667 13.3 13.45C13.5 13.9333 13.7917 14.3667 14.175 14.75L19 19.6L17.6 21L12 15.4L6.4 21Z'
        fill='black'
      />
    </svg>
  );
};
