import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import { Typography } from '/design-systems/Atoms/Typography';
import { NetworkIcon } from '/design-systems/Atoms/Icon/network';
import { Spinner } from '/design-systems/Atoms/Spinner';
import FreeClaimDetails from '/design-systems/Molecules/Cards/MarketplaceCard/FreeClaimDetails';

import { useFeatureFlags } from '/hooks/useFeatureFlags';
import { mergeClassNames } from '/utils/string';
import { getChain } from '/utils/web3';

import { ROUTES } from '/config/routes';
import { useImportToken } from '/hooks/useImportToken';

export const PromptSubmissionActionButton = ({
  assetId,
  price,
  network,
  isOwner,
  isFreeListing,
  chain,
  contractAddress,
  tokenId,
  applyRightMargin = false
}) => {
  const router = useRouter();
  const { isListingPromoEnabled } = useFeatureFlags();

  const canBuy = useMemo(() => price && !isOwner, [price, isOwner]);
  const canList = useMemo(
    () => !price && isOwner && isListingPromoEnabled,
    [price, isOwner, isListingPromoEnabled]
  );

  const { isLoadingToken, onImportToken } = useImportToken({
    chain,
    contractAddress,
    tokenId
  });

  const actionIntent = useMemo(() => {
    if (canList) {
      return ASSET_ACTION_INTENTS.LIST;
    }

    return canBuy && ASSET_ACTION_INTENTS.BUY;
  }, [isOwner, canBuy, canList]);

  const currencySymbol = useMemo(() => {
    return getChain({ chain: network })?.nativeCurrency?.symbol;
  }, [network]);

  const handleClick = (e) => {
    switch (actionIntent) {
      case ASSET_ACTION_INTENTS.LIST:
        e.preventDefault();
        e.stopPropagation();

        if (!assetId) {
          onImportToken();
          return;
        }
        router.push(ROUTES.asset.list(assetId));
        break;
      case ASSET_ACTION_INTENTS.BUY:
        break;
      default:
        break;
    }
  };

  const renderContent = () => {
    switch (actionIntent) {
      case ASSET_ACTION_INTENTS.LIST:
        if (isLoadingToken) {
          <Spinner />;
        }

        return (
          <Typography variant="medium" weight="medium">
            List for sale
          </Typography>
        );
      case ASSET_ACTION_INTENTS.BUY:
        return (
          <>
            <Typography variant="medium" weight="medium">
              {isFreeListing ? 'Claim now' : 'Buy now'}
            </Typography>

            <div className="justify-content flex items-center gap-2">
              <NetworkIcon
                chain={network}
                className={mergeClassNames(
                  'h-5 w-5 object-cover',
                  applyRightMargin && 'mr-10'
                )}
              />
              {isFreeListing ? (
                <FreeClaimDetails
                  classname="text-slate-100 dark:text-black"
                  variant="small"
                />
              ) : (
                <Typography
                  className="text-white dark:text-black"
                  variant="medium"
                  weight="medium"
                >
                  {price} {currencySymbol}
                </Typography>
              )}
            </div>
          </>
        );
    }
  };

  if (!actionIntent) return <></>;

  return (
    <div
      className={mergeClassNames(
        'flex h-[65px] w-full items-center justify-center gap-4',
        'absolute bottom-0 left-0 rounded-b-xl',
        'bg-black opacity-0 transition transition-all duration-200',
        'hover:bg-neutral-800 group-hover:bottom-0 group-hover:opacity-100 dark:bg-white dark:hover:bg-neutral-100',
        'text-white dark:text-black'
      )}
      onClick={handleClick}
    >
      {renderContent()}
    </div>
  );
};

const ASSET_ACTION_INTENTS = {
  BUY: 'BUY',
  LIST: 'LIST'
};
